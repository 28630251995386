import { PrismicRichText } from "@prismicio/react";
import type { RichTextField, RichTextNodeType } from "@prismicio/types";
import * as React from "react";
import { Text } from "ui/base/typography/typography";
import { tarongaTheme } from "./theme";

type RichTextProps = {
  className?: string,
  contents: RichTextField,
};

export const RichText = ({ className, contents }: RichTextProps) => {
  const components: Partial<
    Record<keyof typeof RichTextNodeType, React.FC<{ children: React.ReactNode }>>
  > = React.useMemo(() => ({
    heading2: ({ children }) => <Text className={className} headingLevel={2}>{children}</Text>,
    heading3: ({ children }) => <Text className={className} headingLevel={3}>{children}</Text>,
    paragraph: ({ children }) => <Text className={className}>{children}</Text>,
  }), [className]);
  return <PrismicRichText field={contents} components={components}/>;
};
