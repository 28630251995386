import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { tarongaTheme } from "ui/theme";

interface DrawerUIProps {
  children: React.ReactNode;
  toggleDrawer: () => void;
  isOpen: boolean;
}
export const DrawerUI: React.FC<DrawerUIProps> = ({
  children,
  toggleDrawer,
  isOpen,
}) => {
  const handlersBox = useSwipeable({
    onSwipedUp: ({ event }) => {
      event.stopPropagation();
      toggleDrawer();
    },
    onSwiping: ({ event }) => event.stopPropagation(),
  });

  return (
    <>
      <PinsBar {...handlersBox} onClick={() => toggleDrawer()}>
        {isOpen ? "Hide pins" : "Show pins"}
      </PinsBar>
      <Drawer
        open={isOpen}
        onClose={() => toggleDrawer()}
        direction="bottom"
        style={{
          padding: "24px",
          height: "80%",
          borderRadius: "20px 20px 0px 0px",
          overflowY: "scroll",
        }}
        size={50}
      >
        <div style={{ overflowY: "scroll" }}>{children}</div>
      </Drawer>
    </>
  );
};

export const PinsBar = styled.div<{ color?: string }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 8px;
  width: 300px;
  background-color: ${({ color }) => color ?? tarongaTheme.colorPrimary};
  text-align: center;
  color: white;
  z-index: 2;
`;
