import type { ButtonProps as _ButtonProps } from "@aws-amplify/ui-react";
import { Button as _Button } from "@aws-amplify/ui-react";
import * as React from "react";
import styled, { css } from "styled-components";
import type { Brand } from "../services";
import { UnreachableError } from "./preconditions";

type ButtonProps = Omit<_ButtonProps, "variation"> & {
  variant: "primary" | "secondary",
  children: React.ReactNode,
  brand: Brand,
};

export const Button = React.memo((props: ButtonProps) => {
  return <BaseButton {...props}/>;
});

const BaseButton = styled(_Button)<Pick<ButtonProps, "variant" | "brand">>`
  white-space: nowrap;
  border-radius: 8px;
  border: rgba(0, 0, 0, 0.2) 0.1px solid;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color filter 0.2s linear;
  &:hover {
    filter: drop-shadow(1px 1px 1px gray);
  }
  ${props => {
  switch (props.variant) {
    case "primary":
      return css`
          background-color: ${props.brand.colors.primaryBackground};
          color: ${props.brand.colors.primaryForeground};
        `;
    case "secondary":
      return css`
          background-color: ${props.brand.colors.secondaryBackground};
          color: ${props.brand.colors.secondaryForeground};
        `;
    default:
      throw new UnreachableError(props.variant);
  }
}}
`;
