import * as React from "react";
import styles from "./pin.module.css";

type EmbedProps = {
  html: string,
};

export const Embed = React.memo(({ html }: EmbedProps) => (
  <span className={styles.embed} dangerouslySetInnerHTML={{ __html: html }}/>
));
