import * as React from "react";
import type { AnchorHTMLAttributes } from "react";
import styled from "styled-components";

type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string,
  children: React.ReactNode,
};

export const Link = React.memo((props: LinkProps) => <BaseLink {...props}/>);

const BaseLink = styled.a`
  text-decoration: none;
`;
