import classNames from "clsx";
import * as React from "react";
import { Carousel as CarouselImpl } from "react-responsive-carousel";
import { Arrow } from "../icons/Arrow";
import styles from "./carousel.module.css";
import { Indicator } from "./indicator";

type CarouselProps = {
  wrapperClassName?: string,
  className?: string,
  currentIndex: number,
  onChange: (newIndex: number) => void,
  callToAction?: JSX.Element,
  children: React.ReactElement[],
};

export const Carousel = React.memo(({
  wrapperClassName,
  className,
  currentIndex,
  onChange,
  children,
  callToAction,
}: CarouselProps) => {
  const indicators = children.map((_, i) => {
    const distance = Math.abs(i - currentIndex);
    const isSelected = i === currentIndex;
    const isShrinkable = !(i < 5 && currentIndex < 3)
      && !(i > children.length - 6 && currentIndex > children.length - 4);
    return (
      <Indicator
        key={i}
        isSelected={isSelected}
        distance={isShrinkable ? distance : 0}
        onClick={() => onChange(i)}
      />
    );
  });
  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <CarouselImpl
        className={className}
        selectedItem={currentIndex}
        onChange={onChange}
        children={children}
        swipeScrollTolerance={50}
        preventMovementUntilSwipeScrollTolerance={true}
        infiniteLoop={true}
        renderArrowNext={(onClick, hasNext) => {
          return hasNext ? <Arrow direction="right" onClick={onClick}/> : <React.Fragment/>;
        }}
        renderArrowPrev={(onClick, hasPrev) => {
          return hasPrev ? <Arrow direction="left" onClick={onClick}/> : <React.Fragment/>;
        }}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
      />
      {callToAction}
      {children.length > 1 && (
        <>
          <div className={styles.indicators}>{indicators}</div>
          <div className={styles.status}>{currentIndex + 1}/{children.length}</div>
        </>
      )}
    </div>
  );
});
