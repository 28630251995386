import * as React from "react";
import styled from "styled-components";

export const PageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};

export const Container = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  margin-top: env(safe-area-inset-top);
  padding: 80px 0;
  position: relative;
`;
