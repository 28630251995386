import classNames from "clsx";
import * as React from "react";
import type { Brand } from "services";
import { Text } from "ui/base/typography/typography";
import { Icon } from "ui/icons/icon";
import styles from "./filter.module.css";

type FilterProps = {
  brand: Brand,
  brands: Brand[],
  selectedBrands: Set<string>,
  onBrandSelect: (brand: Brand) => void,
  onClose: () => void,
};

export const Filter = React.memo(
  ({ brands, selectedBrands, onBrandSelect, onClose }: FilterProps) => {
    return (
      <div>
        <div className={styles.titleBar}>
          <Text headingLevel={3}>Filter by zoos</Text>
          <Icon onClick={onClose} glyph="cross" color="black"/>
        </div>
        {brands.map(brand => (
          <div
            key={brand.id}
            className={classNames(styles.filter, {
              [styles.active]: selectedBrands.has(brand.id),
            })}
            onClick={() => onBrandSelect(brand)}
          >
            <Text>{brand.name}</Text>
            {selectedBrands.has(brand.id) && <Icon glyph="check" color="black"/>}
          </div>
        ))}
      </div>
    );
  },
);
