import { Text } from "@aws-amplify/ui-react";
import * as React from "react";
import styled from "styled-components";
import { baseline, x } from "./metrics";

type CardProps = {
  title: string,
  description: JSX.Element,
  button: JSX.Element,
};

export const Card: React.FC<CardProps> = ({ title, description, button }) => {
  return (
    <Container>
      <div>
        <Text fontSize={20} marginBottom={16} fontWeight={600}>{title}</Text>
        {description}
      </div>
      {button}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: ${x(baseline, 2)};
  margin: ${x(baseline, 1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${x(baseline, 2)} ${x(baseline, 4)};
  text-align: left;
`;
