export class UnreachableError extends Error {
  constructor(value: never) {
    super(`Unhandled value: ${value}`);
  }
}

export function exists<T>(t?: T): t is T {
  return t != null;
}

/**
 * Helpers for checking method preconditions.
 * See {@link https://github.com/google/guava/wiki/PreconditionsExplained}
 *
 * Take care not to confuse precondition checking with other types of checks!
 * Precondition failures are used to signal that the *caller* has made an error
 * by calling a method at the wrong time ({@link #checkState}), or with invalid
 * arguments ({@link #checkArgument} and/or {@link #checkExists}).
 */
export class Preconditions {
  /**
   * Checks a condition of a method argument.
   *
   * @param cond condition
   * @param msg optional message template
   * @param args optional arguments for the message template
   * @throws if `cond` is falsey
   */
  static checkArgument(cond: boolean, msg?: string, ...args: any[]): asserts cond {
    if (!cond) {
      throw new Error(msg == null ? "invalid argument" : Preconditions.format(msg, ...args));
    }
  }

  /**
   * Checks that an object is in an appropriate state to receive a method call.
   *
   * @param cond condition
   * @param msg message template
   * @param args optional arguments for the message template
   * @throws if `cond` is falsey
   */
  static checkState(cond: boolean, msg?: string, ...args: any[]): asserts cond {
    if (!cond) {
      throw new Error(msg == null ? "invalid state" : Preconditions.format(msg, ...args));
    }
  }

  /**
   * Checks that two values are equal (===).
   *
   * @throws an error if `a !== b`.
   */
  static checkEquals(
    a: string | number | boolean | symbol | null | undefined,
    b: string | number | boolean | symbol | null | undefined,
    msg?: string,
    ...args: any[]
  ): void;
  static checkEquals(a: any, b: any, msg: string, ...args: any[]): void;
  static checkEquals(a: any, b: any, msg?: string, ...args: any[]) {
    if (a !== b) {
      throw new Error(
        msg == null
          ? Preconditions.format("{} != {}", Preconditions.stringify(a), Preconditions.stringify(b))
          : Preconditions.format(msg, ...args),
      );
    }
  }

  /** Stringifies a data value. */
  private static stringify(x: string | number | boolean | null | undefined | symbol) {
    if (x == null || typeof x === "symbol") {
      return String(x);
    } else {
      try {
        // `JSON.stringify(o)` can throw if `o` is an object with a circular dependency on itself.
        // Even though `x` has been narrowed to the type `string | number | boolean`, for which
        // `JSON.stringify()` is safe, we take additional caution since `Preconditions` is a base
        // module, and a caller could have lied about the type of `x`.
        return JSON.stringify(x);
      } catch (e) {
        return String(x);
      }
    }
  }

  /**
   * Checks that a value exists (not null and not undefined).
   *
   * @param x value
   * @param msg message template
   * @param optional arguments for the message template
   * @throws if `x` is null or undefined
   * @return `x`
   */
  static checkExists(x: null | undefined, msg?: string, ...args: any[]): never;
  static checkExists<T>(x: T | null | undefined, msg?: string, ...args: any[]): T;
  static checkExists<T>(x: T | null | undefined, msg?: string, ...args: any[]): T {
    if (x == null) {
      throw new Error(msg == null ? "argument is null" : Preconditions.format(msg, ...args));
    }
    return x;
  }

  /**
   * Formats a template by interpolating some arguments for `{}` placeholders.
   *
   * @param template template
   * @param args values to substitute for `{}`s in the template
   */
  private static format(template: string, ...args: any[]): string {
    let i = 0;
    return template.replace(/\{}/g, () => i < args.length ? args[i++] : "{}");
  }
}
