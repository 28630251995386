import * as React from "react";
import type { Brand, HomePageSlice } from "services";
import { HomePageSliceType } from "services";
import { Button as ButtonImpl } from "ui/button";
import { Link } from "ui/link";
import { Navigation } from "ui/navigation/navigation";
import type { HomeBootstrap } from "./bootstrap";
import * as HomeComponents from "./home2";
import styles from "./home2.module.css";

type HomeFactoryOpts = {
  bootstrap: HomeBootstrap,
};

export function createHome({ bootstrap }: HomeFactoryOpts) {
  const Header = () => <Navigation brand={bootstrap.brand}/>;
  const Body = () => (
    <>
      {bootstrap.slices.map(getComponentFromSlice.bind(null, bootstrap.brand)).map((C, i) => (
        <C key={i}/>
      ))}
    </>
  );
  return () => <HomeComponents.Home Header={Header} Body={Body}/>;
}

function getComponentFromSlice(brand: Brand, slice: HomePageSlice) {
  switch (slice.type) {
    case HomePageSliceType.HERO: {
      const Button = () => (
        <HomeComponents.HeroButtonsContainer
          brand={brand}
          buttons={slice.buttons.map(b => ({
            label: b.label,
            url: b.url.url,
          }))}
        />
      );
      return () => (
        <HomeComponents.Hero
          title={slice.title}
          subtitle={slice.subtitle}
          imageUrl={slice.image.url}
          Button={Button}
        />
      );
    }
    case HomePageSliceType.CARDS_LIST: {
      const Button = () => (
        <Link href={slice.button.url.url}>
          <ButtonImpl brand={brand} className={styles.button} variant="primary">
            {slice.button.label}
          </ButtonImpl>
        </Link>
      );
      return () => (
        <HomeComponents.LatestPins
          title={slice.title}
          description={slice.description}
          pins={slice.pins}
          Button={Button}
        />
      );
    }
    case HomePageSliceType.CUSTOMER_LOGOS:
      return () => (
        <HomeComponents.PartnerZoos
          title={slice.title}
          description={slice.description}
          zoos={slice.logos.map(l => ({
            linkUrl: l.link,
            imageUrl: l.image.url,
            imageAlt: l.image.alt ?? "",
          }))}
        />
      );
    case HomePageSliceType.FAQ:
      return () => <HomeComponents.FAQ title={slice.title} questions={slice.items}/>;
    case HomePageSliceType.BANNER:
      return () => (
        <HomeComponents.StartJourney
          imageSrc={slice.image.url}
          imageAlt={slice.image.alt ?? ""}
          title={slice.title}
          description={slice.description}
          buttonText={slice.button.label}
          buttonLink={slice.button.url.url}
        />
      );
  }
}
