import * as React from "react";

export class Store<T extends { [key: string]: any }> {
  private readonly listeners = new Set<(currentState: T) => void>();
  private inBatch = false;
  private state: T;

  constructor(initialState: T) {
    this.state = Object.freeze(initialState);
  }

  get() {
    return this.state;
  }

  subscribe(listener: (currentState: T) => void) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  update(mapState: (currentState: T) => T) {
    this.state = Object.freeze(mapState(this.state));
    this.maybePublish();
  }

  batch(batchUpdateCallback: () => void) {
    this.inBatch = true;
    batchUpdateCallback();
    this.inBatch = false;
    this.maybePublish();
  }

  private maybePublish() {
    if (this.inBatch) {
      return;
    }
    this.listeners.forEach(l => l(this.state));
  }
}

export const useStore = <T>(store: Store<T>) => {
  return React.useSyncExternalStore<T>(store.subscribe.bind(store), store.get.bind(store));
};

type StoreMap = {
  [key: string]: Store<any>,
};

export function createMapObserver<T extends StoreMap>(storeMap: T) {
  return <U extends { [key: string]: any, store: { [K in keyof T]: ReturnType<T[K]["get"]> } }>(
    Component: React.ComponentType<U>,
  ): React.ComponentType<Omit<U, "store">> =>
  (props: Omit<U, "store">) =>
    React.createElement(Component, {
      ...props,
      store: Object.fromEntries(Object.entries(storeMap).map(([k, v]) => [k, useStore(v)])),
    } as U);
}

export function createObserver<T>(
  store: Store<T>,
) {
  return <U extends { [key: string]: any, store: T }>(
    Component: React.ComponentType<U>,
  ): React.ComponentType<Omit<U, "store">> =>
  (props: Omit<U, "store">) =>
    React.createElement(Component, {
      ...props,
      store: useStore(store),
    } as U);
}

export function withEffect(effect: () => (() => void) | void) {
  return <T>(Component: React.ComponentType<T>) => (props: T) => {
    React.useEffect(effect);
    return React.createElement(Component, props);
  };
}

export const createReducer =
  <T, K extends keyof T>(store: Store<T>, key: K) => (valueOrCallback: T[K]) => {
    store.update(state => ({
      ...state,
      [key]: valueOrCallback,
    }));
  };

export const createCallbackReducer =
  <T, K extends keyof T>(store: Store<T>, key: K) => (callback: (value: T[K]) => T[K]) =>
    store.update(state => ({
      ...state,
      [key]: callback(state[key]),
    }));
