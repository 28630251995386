import { tarongaTheme } from "../../ui/theme";

export const UNKNOWN_BRAND = {
  id: "unknown_brand",
  name: "Unknown brand",
  logo: {
    image: {
      url: "", // TODO: Put in a placeholder image
      alt: "Unknown brand",
    },
    href: "#",
  },
  colors: {
    primary: "black",
    primaryBackground: "black",
    primaryForeground: "white",
  },
  featuredPinIds: [],
};
