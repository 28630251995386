import * as React from "react";
import { Button } from "ui/button";
import { Navigation } from "ui/navigation/navigation";
import { exists } from "ui/preconditions";
import { createObserver, Store } from "ui/store/store";
import type { PinsHomeBootstrap } from "./bootstrap";
import { createPinsCarousel } from "./carousel/create";
import { PinsCatalog } from "./catalog/catalog";
import { PinsHome } from "./home";

type PinsHomeFactoryOpts = {
  bootstrap: PinsHomeBootstrap,
  navigate: (to: string) => void,
};

const UNRELEASED_PINS = new Set([
  "78919b0b-2181-4070-af0d-828600632f59", // Gorilla from Zoos Victoria
]);

export const createPinsHome = ({
  bootstrap,
  navigate,
}: PinsHomeFactoryOpts) => {
  const { brand, brands, pins, auth: { user } } = bootstrap;

  const userPinIds = new Set(user?.attributes.pin_ids ?? []);
  const userPins = pins.filter(pin => userPinIds.has(pin.id)).filter(exists);
  const userCollectionCarouselPins = userPins.map(pin => ({
    ...pin,
    // TODO: Improve time complexity
    featuredBy: new Set(brands.filter(b => new Set(b.featuredPinIds).has(pin.id)).map(b => b.id)),
    locked: false,
  }));
  const UserPins = bootstrap.enableCarouselView
    ? createPinsCarousel({
      navigate,
      brands,
      parentBrand: brand,
      pins: userCollectionCarouselPins,
      title: "My collection",
    })
    : undefined;

  const featuredPins = pins.filter(pin => !userPinIds.has(pin.id)).map(pin => ({
    ...pin,
    locked: true,
    featuredBy: new Set(brands.filter(b => new Set(b.featuredPinIds).has(pin.id)).map(b => b.id)),
  }));
  const FeaturedPins = bootstrap.enableCarouselView
    ? createPinsCarousel({
      pins: featuredPins,
      navigate,
      brands,
      parentBrand: brand,
      title: "Discover new pins",
    })
    : undefined;

  const ScanButton = () => (
    <Button
      variant="primary"
      brand={brand}
      onClick={() => navigate("/pinz/scan")}
    >
      Click here to scan a pin
    </Button>
  );

  const store = new Store({
    showCatalog: !bootstrap.enableCarouselView,
  });

  const CatalogButton = createObserver(store)(({ store: { showCatalog } }) => (
    <>
      {!showCatalog && (
        <Button
          variant="primary"
          brand={brand}
          onClick={() => store.update(() => ({ showCatalog: true }))}
        >
          See entire pinz catalog
        </Button>
      )}
      {showCatalog && (
        <>
          {bootstrap.enableCarouselView && (
            <Button
              variant="primary"
              brand={brand}
              onClick={() => store.update(() => ({ showCatalog: false }))}
            >
              Hide pinz catalog
            </Button>
          )}
          <PinsCatalog
            showTitle={bootstrap.enableCarouselView}
            pins={pins.filter(pin => userPinIds.has(pin.id) || !UNRELEASED_PINS.has(pin.id)).map(
              pin => ({
                ...pin,
                disabled: !userPinIds.has(pin.id),
              })
            )}
          />
        </>
      )}
    </>
  ));

  const Body = () => <>{UserPins && <UserPins/>} {FeaturedPins && <FeaturedPins/>}</>;

  return () => (
    <>
      <Navigation brand={brand}/>
      <PinsHome ScanButton={ScanButton} Body={Body} CatalogButton={CatalogButton}/>
    </>
  );
};
