import type { RichTextField } from "@prismicio/types";

export type Pin = {
  id: string,
  icon: Image,
  name: string,
  species: string,
  overview: any,
  embed?: {
    html: string,
  },
  images: {
    image: Image,
  }[],
  countries: Country[],
  animal_origin: AnimalOrigin[],
  info: Info[],
  challenges: Challenge[],
  actions: HowToHelpItem[],
  buttons: Button[],
  action_cards: ActionCard[],
  brand: Brand,
};

export type PinMetadata = {
  id: string,
  name: string,
  icon: Image,
  disabled?: boolean,
  brand?: Brand,
};

export type Brand = {
  id: string,
  name: string,
  logo: {
    image: Image,
    href: string,
  },
  colors: {
    primary: string,
    primaryBackground: string,
    primaryForeground: string,
    secondaryBackground?: string,
    secondaryForeground?: string,
  },
  featuredPinIds: string[],
};

export type Image = {
  url: string,
  alt: string | null,
};

export type Challenge = {
  challenge: string,
};

export type HowToHelpItem = {
  label: string,
  url?: {
    url: string,
  },
};

export type Media = {
  type: "image" | "video",
  url: string,
};

export type Info = {
  column: string,
  value: string,
};

export type Country = {
  country: string,
};

export type AnimalOrigin = {
  image: Image,
  description: string,
};

export type Button = {
  label: string,
  url: {
    url: string,
  },
};

export type ActionCard = {
  title: string,
  description: RichTextField,
  button_label: string,
  button_url: {
    url: string,
  },
};

export type Location = {
  lat: number,
  long: number,
};

export type AnonymousAttributes = {
  pin_ids: string[],
  pins?: (1 | 0)[],
};

export type Attributes = AnonymousAttributes & {
  name: string,
  country: string,
  state: string,
  suburb: string,
  birth_month: number,
  birth_year: number,
};

export type AuthenticatedUser = {
  isAnonymous: false,
  id: string,
  attributes: Attributes,
};

export type AnonymousUser = {
  isAnonymous: true,
  id: string,
  attributes: AnonymousAttributes,
};

export type User = AnonymousUser | AuthenticatedUser;

export enum HomePageSliceType {
  HERO,
  CARDS_LIST,
  CUSTOMER_LOGOS,
  FAQ,
  BANNER,
}

export type HomePageSlice = {
  type: HomePageSliceType.HERO,
  title: string,
  subtitle: string,
  image: Image,
  buttons: Button[],
} | {
  type: HomePageSliceType.CARDS_LIST,
  title: string,
  description: RichTextField,
  button: Button,
  pins: PinMetadata[],
} | {
  type: HomePageSliceType.CUSTOMER_LOGOS,
  title: string,
  description: RichTextField,
  logos: {
    image: Image,
    link: string,
  }[],
} | {
  type: HomePageSliceType.FAQ,
  title: string,
  items: {
    question: string,
    answer: RichTextField,
  }[],
} | {
  type: HomePageSliceType.BANNER,
  title: string,
  description: string,
  image: Image,
  button: Button,
};

export type HomePage = HomePageSlice[];
