import type { RichTextField } from "@prismicio/types";
import * as React from "react";
import { RichText } from "ui/rich-text";
import styles from "./pin.module.css";

type OverviewProps = {
  // @TODO: Refactor rich text field data type to something agnostic to Primsic
  contents: RichTextField,
};

export const Overview = React.memo(({ contents }: OverviewProps) => (
  <div className={styles.overview}>
    <RichText contents={contents}/>
  </div>
));
