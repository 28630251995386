import type { RichTextField } from "@prismicio/types";
import clsx from "clsx";
import * as React from "react";
import type { Brand, PinMetadata } from "services";
import { Text } from "ui/base/typography/typography";
import { Button } from "ui/button";
import { Link } from "ui/link";
import { RichText } from "ui/rich-text";
import { ReactComponent as Curve } from "./curve.svg";
import styles from "./home2.module.css";
import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as QuestionMarkImage } from "./question-mark.svg";

type HomeProps = {
  Header?: React.ComponentType,
  Body?: React.ComponentType,
};

export const Home = ({ Header, Body }: HomeProps) => {
  return <div className={styles.pageWrapper}>{Header && <Header/>} {Body && <Body/>}</div>;
};

type HeroButtonsContainerProps = {
  brand: Brand,
  buttons: {
    label: string,
    url: string,
  }[],
};

export const HeroButtonsContainer = ({ brand, buttons }: HeroButtonsContainerProps) => {
  return (
    <div className={styles.heroButtonsContainer}>
      {buttons.map(({ label, url }, index) => (
        <Link key={index} href={url}>
          <Button
            className={clsx(styles.button, styles.heroButton)}
            variant="primary"
            brand={brand}
          >
            {label}
          </Button>
        </Link>
      ))}
    </div>
  );
};

type HeroProps = {
  title: string,
  subtitle: string,
  Button: React.ComponentType,
  imageUrl: string,
};

export const Hero: React.FC<HeroProps> = ({ title, subtitle, Button, imageUrl }) => {
  return (
    <div id="Hero" className={styles.hero}>
      <div className={styles.heroBgElements}>
        <div className={styles.heroBgElement1}></div>
        <div className={styles.heroBgElement2}></div>
        <div className={styles.heroBgElement3}></div>
        <div className={styles.heroBgElement4}></div>
      </div>
      <div className={clsx(styles.wrapper, styles.flexRow)}>
        <div className={clsx(styles.heroIntro)}>
          <Text
            color="white"
            className={clsx(styles.heroIntroText, styles.heroIntroTitle)}
            headingLevel={1}
          >
            {title}
          </Text>
          <Text color="white" className={clsx(styles.heroIntroText, styles.heroIntroSub)}>
            {subtitle}
          </Text>
          <Button/>
        </div>
        <img src={imageUrl} width="400" alt="" className={styles.heroImage}/>
      </div>
      <Curve className={styles.curve}/>
    </div>
  );
};

type LatestPinsProps = {
  title: string,
  description: RichTextField,
  pins: PinMetadata[],
  Button: React.ComponentType,
};

export const LatestPins: React.FC<LatestPinsProps> = (
  { pins, title, description, Button },
) => {
  return (
    <div className={clsx(styles.section, styles.wrapper)}>
      <div className={clsx(styles.centered)}>
        <Text headingLevel={2}>{title}</Text>
        <div className={clsx(styles.divider, styles.animated)}></div>
        <RichText className={styles.descriptionText} contents={description}/>
      </div>
      <div className={styles.cardsList}>
        {pins.map((pin, index) => (
          <div key={index} className={styles.card}>
            <img
              src={pin.icon.url}
              loading="lazy"
              alt={pin.icon.alt ?? ""}
              className={styles.cardImage}
            />
            <div className={styles.cardHeading}>
              <strong>{pin.name}</strong>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.cardsListBottom}>
        <Button/>
      </div>
    </div>
  );
};

type Zoo = {
  imageUrl: string,
  imageAlt: string,
  linkUrl: string,
};

type PartnerZoosProps = {
  title: string,
  description: RichTextField,
  zoos: Zoo[],
};

export const PartnerZoos: React.FC<PartnerZoosProps> = ({ title, description, zoos }) => {
  return (
    <div className={clsx(styles.section, styles.wrapper)}>
      <div className={styles.centered}>
        <Text headingLevel={2}>{title}</Text>
        <div className={clsx(styles.divider, styles.animated)}></div>
        <RichText className={styles.descriptionText} contents={description}/>
      </div>
      <div className={styles.logosList}>
        {zoos.map((zoo, index) => (
          <Link
            key={index}
            href={zoo.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.logoLink}
          >
            <img src={zoo.imageUrl} alt={zoo.imageAlt} className={styles.logo}/>
          </Link>
        ))}
      </div>
    </div>
  );
};

type FAQQuestion = {
  question: string,
  answer: RichTextField,
};

type FAQProps = {
  title: string,
  questions: FAQQuestion[],
};

export const FAQ: React.FC<FAQProps> = ({ title, questions }) => {
  return (
    <div className={clsx(styles.section, styles.haze)}>
      <div className={clsx(styles.wrapper, styles.faq)}>
        <div className={styles.faqIntro}>
          <QuestionMarkImage className={styles.icon}/>
          <Text headingLevel={2}>{title}</Text>
        </div>
        <div className={styles.faqBody}>
          {questions.map((q, index) => (
            <div key={index} className={styles.faqItem}>
              <InfoIcon className={styles.infoIcon}/>
              <div className={styles.faqItemInfo}>
                <Text headingLevel={4}>{q.question}</Text>
                <RichText contents={q.answer}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Contact: React.FC = () => {
  return (
    <div className={clsx(styles.section, styles.haze, styles.noPaddingTop, styles.wfSection)}>
      <div className={styles.wrapper}>
        <div style={{ opacity: 0 }} className={styles.contact}>
          <div className={styles.contactInfo}>
            <h2>Contact us</h2>
            <div className={clsx(styles.fullWidth, styles.wForm)}>
              <form name="email-form" method="get" className={styles.contactForm}>
                <div className={styles.formGroup}>
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    className={styles.input}
                    maxLength={256}
                    name="Contact-Name-2"
                    placeholder="James Bond"
                    id="Contact-Name-2"
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="email-3">Email Address</label>
                  <input
                    type="email"
                    className={styles.input}
                    maxLength={256}
                    name="Contact-Email-2"
                    placeholder="james@bond.com"
                    id="Contact-Email-2"
                    required
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="Contact-Subject">Subject</label>
                  <input
                    type="text"
                    className={styles.input}
                    maxLength={256}
                    name="Contact-Subject"
                    placeholder="Enter the subject"
                    id="Contact-Subject"
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="email-3">Message</label>
                  <textarea
                    id="field-2"
                    name="field-2"
                    placeholder="How can we help you?"
                    maxLength={5000}
                    required
                    className={clsx(styles.input, styles.textArea)}
                  />
                </div>
                <input
                  type="submit"
                  value="Submit Message"
                  data-wait="Please wait..."
                  className={clsx(styles.button, styles.wButton)}
                />
              </form>
              <div className={styles.formSuccess}>
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className={styles.formError}>
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type StartJourneyProps = {
  imageSrc: string,
  imageAlt: string,
  title: string,
  description: string,
  buttonText: string,
  buttonLink: string,
};

export const StartJourney: React.FC<StartJourneyProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  buttonText,
  buttonLink,
}) => {
  return (
    <div className={clsx(styles.section, styles.hero)}>
      <div className={styles.heroBgElements}>
        <div className={styles.heroBgElement1}></div>
        <div className={styles.heroBgElement2}></div>
        <div className={styles.heroBgElement3}></div>
        <div className={styles.heroBgElement4}></div>
      </div>
      <div className={clsx(styles.wrapper, styles.startJourney)}>
        <img src={imageSrc} width="1000" alt={imageAlt} className={styles.startJourneyImage}/>
        <Text headingLevel={2} color="white" fontSize={32} marginBottom={8}>{title}</Text>
        <Text color="white" marginBottom={24}>{description}</Text>
        <Link href={buttonLink} className={styles.button}>{buttonText}</Link>
      </div>
    </div>
  );
};
