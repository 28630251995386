import * as React from "react";
import { createObserver, Store } from "../store/store";
import { Carousel } from "./carousel";

export function createCarousel() {
  const store = new Store({
    currentIndex: 0,
  });
  const onChange = (currentIndex: number) => store.update(() => ({ currentIndex }));
  const Component = createObserver(store)((
    { store, children }: { store: { currentIndex: number }, children: React.ReactElement[] },
  ) => <Carousel currentIndex={store.currentIndex} onChange={onChange} children={children}/>);
  return {
    store,
    Component,
  };
}
