import React from "react";
import styled from "styled-components";

interface ListContainerProps {
  gap: number;
  minColumnWidth: number;
}
interface IconListProps {
  children: React.ReactNode;
  minColumnWidth: number;
  gap: number;
  direction?: "row" | "column";
}

export const IconList: React.FC<IconListProps> = ({
  children,
  direction = "column",
}) => {
  return <ListContainer direction={direction}>{children}</ListContainer>;
};

const ListContainer = styled.div<{ direction: "row" | "column" }>`
  display: flex;
  flex-direction: ${props => props.direction};
  flex-wrap: ${props => props.direction === "row" ? "wrap" : "nowrap"};
  justify-content: center;
  align-items: center;
`;
