import { Table, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react";
import * as React from "react";
import { Text } from "ui/base/typography/typography";
import styles from "./pin.module.css";

type TraitsProps = {
  title: string,
  traits: {
    // @TODO: Rename this to `label`
    column: string,
    value: string,
  }[],
};

export const Traits = React.memo(({ title, traits }: TraitsProps) => (
  <div className={styles.section}>
    <Text marginBottom={16} headingLevel={2}>{title}</Text>
    {/* TODO: Create UI component for table */}
    <Table size="small">
      <TableBody>
        {traits.map((info, index) => (
          <TableRow key={`info-${index}`}>
            <TableCell paddingRight={24}>
              <Text as="strong">{info.column}</Text>
            </TableCell>
            <TableCell>
              <Text as="span">{info.value}</Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
));
