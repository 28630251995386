import * as React from "react";
import type { RouteObject } from "react-router-dom";
import { createObserver, Store } from "../store/store";

type RequestHandler = ({ request, params }: {
  request: Request,
  params: Record<string, string | undefined>,
}) => Promise<React.ComponentType> | React.ComponentType;

export class Router {
  readonly routes: RouteObject[] = [];
  constructor(
    private readonly Loader: React.ComponentType = React.Fragment,
  ) {
  }

  private stripSlash(path: string): string {
    if (path.startsWith("/")) {
      return this.stripSlash(path.substring(1));
    }
    return path;
  }

  use(path: string, router: Router) {
    this.routes.push({
      path,
      children: router.routes,
    });
    return this;
  }

  get(path: string, handler: RequestHandler) {
    const box = new Store(this.Loader);
    this.routes.push({
      path: this.stripSlash(path),
      loader: async ({ request, params }) => {
        const Component = await handler({ request, params });
        box.update(() => Component);
        return null;
      },
      Component: createObserver(box)(({ store }) => React.createElement(store)),
    });
    return this;
  }
}
