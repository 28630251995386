import type { IconProps } from "@aws-amplify/ui-react";
import * as React from "react";

type Props = Omit<IconProps, "ariaLabel"> & {
  direction: "right" | "left",
  onClick: () => void,
};

export const Arrow = (props: Props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        zIndex: 1,
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        right: props.direction === "left" ? undefined : "0",
        left: props.direction === "left" ? "0" : undefined,
        transform: `translateY(-50%) ${props.direction === "left" ? "rotate(180deg)" : ""}`,
        padding: "0px 12px 5px",
        background: "rgba(50, 50, 50, 0.5)",
        borderTopLeftRadius: "12px",
        borderBottomLeftRadius: "12px",
      }}
    >
      <span
        style={{
          fontSize: "24px",
          color: "white",
        }}
      >
        &#8594;
      </span>
    </div>
  );
};
