import { Image } from "@aws-amplify/ui-react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import type { Brand } from "../services";
import { baseline, x } from "./metrics";

interface PinIconProps {
  pinId: string;
  imageUrl: string;
  brand?: Brand;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  label?: string;
  style?: React.CSSProperties;
}

export const PinIcon: React.FC<PinIconProps> = ({
  brand,
  label,
  imageUrl,
  pinId,
  isDisabled = true,
  width = "auto",
  height = "auto",
  onClick,
  style,
}) => {
  return (
    <Link
      style={{ textDecoration: "none", ...style, pointerEvents: isDisabled ? "none" : "initial" }}
      to={isDisabled ? "#" : `/pinz/${pinId}`}
      onClick={() => (onClick ? onClick() : null)}
    >
      <Card>
        <Image
          alt={pinId}
          src={imageUrl}
          isDisabled={isDisabled}
          opacity={isDisabled ? 0.2 : 1}
          style={{ width: `${width}`, height: `${height}` }}
          objectFit="contain"
        />
        <Label>{label}</Label>
        {brand && (
          <Image
            alt={brand.logo.image.alt ?? ""}
            src={brand.logo.image.url}
            width="auto"
            height="50px"
          />
        )}
      </Card>
    </Link>
  );
};

const Card = styled.div`
  padding: ${x(baseline, 1)};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: ${x(baseline, 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-bottom: ${x(baseline, 4)};
  * {
    text-decoration: none;
    color: black;
    text-align: center;
  }
`;

const Label = styled.div`
  margin: ${x(baseline, 1)};
  color: ${({ theme }) => theme.colorTypographyPrimary};
  font-weight: 600;
`;
