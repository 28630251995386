import { Heading, Text as TextImpl } from "@aws-amplify/ui-react";
import type { TextProps as TextImplProps } from "@aws-amplify/ui-react";
import classnames from "classnames";
import * as React from "react";
import type { Brand } from "../../../services";
import { UNKNOWN_BRAND } from "../../../services/content/fake";
import styles from "./typography.module.css";

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type TextProps = TextImplProps & {
  brand?: Brand,
  headingLevel?: HeadingLevel,
  children?: React.ReactNode,
};

export const Text = ({ brand = UNKNOWN_BRAND, headingLevel, className, ...rest }: TextProps) => {
  if (headingLevel) {
    return (
      <Heading
        className={classnames({
          [styles.textTitleLarge]: headingLevel === 1,
          [styles.textTitleMedium]: headingLevel === 2,
          [styles.textTitleSmall]: headingLevel === 3,
          [styles.textTitleExtraSmall]: headingLevel === 4,
          [styles.textTitleExtraExtraSmall]: headingLevel >= 5,
        }, className)}
        color={headingLevel <= 2 ? brand.colors.primary : "#000000"}
        {...rest}
      />
    );
  }
  return <TextImpl color="#000000" className={className} {...rest}/>;
};
