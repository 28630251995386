import { Image } from "@aws-amplify/ui-react";
import * as React from "react";
import type { Image as ImageData } from "services";
import styles from "./pin.module.css";

type PinIconProps = {
  image: ImageData,
};

export const PinIcon = React.memo(({ image }: PinIconProps) => (
  <div className={styles.imageWrapper}>
    <Image className={styles.image} alt={image.alt ?? "Pin Icon"} src={image.url}/>
  </div>
));
