import classNames from "clsx";
import * as React from "react";
import styles from "./indicator.module.css";

type IndicatorProps = {
  isSelected: boolean,
  distance: number,
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void,
};

export const Indicator = ({ isSelected, distance, onClick }: IndicatorProps) => (
  <span
    className={classNames(styles.indicator, {
      [styles.active]: isSelected,
      [styles.hidden]: distance > 3,
      [styles.small]: distance > 2,
    })}
    onClick={onClick}
  />
);
