import type { RichTextField } from "@prismicio/types";
import * as React from "react";
import type { Brand } from "services";
import { Button } from "ui/button";
import { Card } from "ui/card";
import { RichText } from "ui/rich-text";
import styles from "./pin.module.css";

type ActionCardsProps = {
  brand: Brand,
  items: {
    title: string,
    description: RichTextField,
    button_url: {
      url: string,
    },
    button_label: string,
  }[],
};

export const ActionCards = React.memo(({ brand, items }: ActionCardsProps) => (
  <div className={styles.actionCards}>
    {items.map((actionCard, index) => (
      <Card
        key={index}
        title={actionCard.title}
        description={<RichText contents={actionCard.description}/>}
        button={(
          <Button
            brand={brand}
            variant="primary"
            onClick={() => {
              window.location.href = actionCard.button_url.url;
            }}
          >
            {actionCard.button_label}
          </Button>
        )}
      />
    ))}
  </div>
));
