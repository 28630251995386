import * as React from "react";
import { Text } from "ui/base/typography/typography";
import { Navigation } from "ui/navigation/navigation";
import { ActionCards as ActionCardsImpl } from "./action-cards";
import { BackButton as BackButtonImpl } from "./back-button";
import type { PinsPinBootstrap } from "./bootstrap";
import { CallToAction as CallToActionImpl } from "./call-to-action";
import { createCarousel } from "./carousel/create";
import { Challenges as ChallengesImpl } from "./challenges";
import { Embed as EmbedImpl } from "./embed";
import { HowToHelp as HowToHelpImpl } from "./how-to-help";
import { Origin as OriginImpl } from "./origin";
import { Overview as OverviewImpl } from "./overview";
import { PinsPin } from "./pin";
import { PinIcon as PinIconImpl } from "./pin-icon";
import { Traits as TraitsImpl } from "./traits";

type PinsPinFactoryOpts = {
  bootstrap: PinsPinBootstrap,
  navigate: (to: string) => void,
};

export function createPinsPin({
  bootstrap,
  navigate,
}: PinsPinFactoryOpts) {
  const { pin } = bootstrap;

  const BackButton = () => <BackButtonImpl brand={pin.brand} onClick={() => navigate("/pinz")}/>;
  const PinIcon = () => <PinIconImpl image={pin.icon}/>;
  const Title = () => <Text headingLevel={1} brand={pin.brand}>{pin.name}</Text>;
  const Species = () => <Text headingLevel={2} brand={pin.brand}>{pin.species}</Text>;
  const Carousel = createCarousel({ images: pin.images.map(({ image }) => image) });
  const Overview = () => <OverviewImpl contents={pin.overview}/>;
  const Traits =
    () => (pin.info.length > 0 ? <TraitsImpl title="Traits" traits={pin.info}/> : null);
  const Challenges = () => <ChallengesImpl title="Challenges" challenges={pin.challenges}/>;
  const Origin = () => <OriginImpl brand={pin.brand} title="Located in" items={pin.animal_origin}/>;
  const Embed = () => (
    pin.embed?.html ? <EmbedImpl html={pin.embed?.html}/> : null
  );
  const HowToHelp = () =>
    pin.actions.length > 0
      ? <HowToHelpImpl brand={pin.brand} title="How to help" items={pin.actions}/>
      : null;
  const CallToAction = () => <CallToActionImpl brand={pin.brand} items={pin.buttons}/>;
  const ActionCards = () => <ActionCardsImpl brand={pin.brand} items={pin.action_cards}/>;

  return () => {
    return (
      <>
        <Navigation brand={pin.brand} linkOverride="/pinz"/>
        <PinsPin
          brand={pin.brand}
          Icon={PinIcon}
          Title={Title}
          Species={Species}
          Carousel={Carousel}
          Overview={Overview}
          Traits={Traits}
          Challenges={Challenges}
          Origin={Origin}
          Embed={Embed}
          HowToHelp={HowToHelp}
          CallToAction={CallToAction}
          ActionCards={ActionCards}
        />
      </>
    );
  };
}
