import { Image } from "@aws-amplify/ui-react";
import * as React from "react";
import type { Image as ImageData } from "services";

type CarouselProps = {
  BaseCarousel: React.ComponentType<{ children: React.ReactElement[] }>,
  images: ImageData[],
};

export const Carousel = React.memo(({ BaseCarousel, images }: CarouselProps) => {
  return (
    <BaseCarousel>
      {images.map((image, index) => (
        <Image alt={image.alt ?? "Carousel image"} src={image.url} key={index}/>
      ))}
    </BaseCarousel>
  );
});
