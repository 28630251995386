import * as React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { colorGreenT6 } from "./colors";
import { baseline } from "./metrics";

type Props = {
  route?: string,
  onClick?: () => void,
  label: string,
};

export const BackButton: React.FC<Props> = ({ route, onClick, label }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    onClick?.();
    route && navigate(route);
  };
  return (
    <Bar>
      <Back onClick={handleOnClick}>&lt; {label}</Back>
    </Bar>
  );
};

const Back = styled.button`
  font-size: 16px;
  padding: 4px;
  height: 24px;
  color: ${colorGreenT6};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Bar = styled.div`
  background-color: ${props => props.theme.colorWhite};
  padding: ${baseline};
  display: flex;
`;
