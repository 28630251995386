const enum PinName {
  CAPYBARA = "capybara",
  ELEPHANT = "elephant",
  FROG = "frog",
  GIRAFFE = "giraffe",
  KANGAROO = "kangaroo",
  KOALA = "koala",
  LION = "lion",
  MEERKAT = "meerkat",
  PLATYPUS = "platypus",
  RED_PANDA = "red_panda",
  RHINO = "rhino",
  TARONGA_DUBBO = "taronga_dubbo",
  TARONGA_SKY_SAFARI = "taronga_sky_safari",
  TARONGA_SYDNEY = "taronga_sydney",
}

const pinIdByName = {
  [PinName.CAPYBARA]: "e169bca9-2b82-4f6d-9231-cb8a67099f5c",
  [PinName.ELEPHANT]: "91d01f90-154e-4171-af2d-4ffb001f191e",
  [PinName.FROG]: "4b950f78-9de5-434e-b501-6b1d2450e2a4",
  [PinName.GIRAFFE]: "d5b2d3fe-7902-4153-8930-52430bea77f2",
  [PinName.KANGAROO]: "595738bb-5631-43af-b0bd-33aa493fd3b4",
  [PinName.KOALA]: "34592400-de63-4b06-81be-0f0c13c63c1e",
  [PinName.LION]: "1ffaac71-7586-41e7-a2c4-8526e7dff056",
  [PinName.MEERKAT]: "ee9a3988-13c8-48ec-949a-15304a02e2b7",
  [PinName.PLATYPUS]: "e01ca87b-084a-4762-b394-4a9805912b1d",
  [PinName.RED_PANDA]: "1908af9b-a422-4d44-bb7c-6377337dd8f8",
  [PinName.RHINO]: "bfc8ddad-23cd-4223-8836-19efcbce7a53",
  [PinName.TARONGA_DUBBO]: "7235a65b-93fd-488c-b036-b80f19d96142",
  [PinName.TARONGA_SKY_SAFARI]: "07c85864-92f1-4462-9dbe-3cbfbc2dd91e",
  [PinName.TARONGA_SYDNEY]: "00d10aa0-7f45-4b21-a129-da790bd52ca3",
};

export function installNativeService(window: Window, navigate: (to: string) => void) {
  const onCompleteScan = (pinName: PinName) => {
    navigate(`/pinz/${pinIdByName[pinName]}`);
  };
  (window as any).onCompleteScan = onCompleteScan;
}
