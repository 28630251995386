import * as React from "react";
import type { Brand } from "services";
import { Button } from "ui/button";
import styles from "./pin.module.css";

type CallToActionProps = {
  brand: Brand,
  items: {
    label: string,
    url: {
      url: string,
    },
  }[],
};

export const CallToAction = React.memo(({ brand, items }: CallToActionProps) => (
  <div className={styles.cta}>
    {items.map((button, index) => (
      <Button
        className={styles.button}
        brand={brand}
        variant="primary"
        key={`action-button-${index}`}
        onClick={() => {
          window.location.href = button.url.url;
        }}
      >
        {button.label}
      </Button>
    ))}
  </div>
));
