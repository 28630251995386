import * as React from "react";
import type { Brand } from "services";
import { Text } from "ui/base/typography/typography";
import styles from "./pin.module.css";

type HowToHelpProps = {
  brand: Brand,
  title: string,
  items: {
    url?: {
      url?: string,
    },
    label: string,
  }[],
};

export const HowToHelp = React.memo(({ brand, title, items }: HowToHelpProps) => (
  <div className={styles.section}>
    <Text brand={brand} headingLevel={2}>{title}</Text>
    {items.map((item, index) => (
      <Text key={`animal-how-to-help-${index}`}>
        <a href={item.url?.url ?? "#"}>{item.label}</a>
      </Text>
    ))}
  </div>
));
