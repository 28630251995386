import classNames from "clsx";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Transition, { ENTERED, ENTERING } from "react-transition-group/Transition";
import styles from "./sheet.module.css";

const animationDuration = parseInt(styles.transitionDuration, 10);

type SheetProps = {
  isOpen: boolean,
  onClose: () => void,
  className?: string,
};

export class Sheet extends React.PureComponent<React.PropsWithChildren<SheetProps>> {
  private readonly portalContainer = document.createElement("div");
  private nodeRef = React.createRef<HTMLDivElement>();

  override componentDidMount() {
    document.body.appendChild(this.portalContainer);
  }

  override componentWillUnmount() {
    document.body.removeChild(this.portalContainer);
  }

  /**
   * The `Transition` component applies the state ENTERING in the same tick
   * as it mounts its child, which does not allow for having an entering animation.
   * This function is used to force the browser to mount in a separate tick
   * and then apply the styles for the ENTERING state. More info:
   * https://github.com/reactjs/react-transition-group/issues/223
   */
  private readonly reflowBrowser = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.nodeRef.current?.offsetHeight;
  };

  override render() {
    return (
      <Transition
        in={this.props.isOpen}
        onEnter={this.reflowBrowser}
        onExit={this.reflowBrowser}
        timeout={animationDuration}
        mountOnEnter={true}
        unmountOnExit={true}
        nodeRef={this.nodeRef}
      >
        {state =>
          ReactDOM.createPortal(
            (
              <div className={styles.sheetContainer} onClick={this.props.onClose}>
                <div
                  className={classNames(this.props.className, styles.sheet, {
                    [styles.enter]: state === ENTERING || state === ENTERED,
                  })}
                  ref={this.nodeRef}
                  onClick={e => e.stopPropagation()}
                >
                  {this.props.children}
                </div>
              </div>
            ),
            this.portalContainer,
          )}
      </Transition>
    );
  }
}
