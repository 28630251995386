import {
  colorBlueT2,
  colorBlueT5,
  colorBlueT6,
  colorGreenT2,
  colorGreenT5,
  colorGreenT6,
  colorGreenT7,
  colorGreenT8,
  colorNeutralT1,
  colorNeutralT2,
  colorNeutralT7,
  colorNeutralT8,
  colorNeutralT9,
  colorRedT1,
  colorRedT5,
  colorWhite,
} from "./colors";

export const tarongaTheme = {
  colorPrimary: colorGreenT5,
  colorPrimaryForeground: colorWhite,
  colorPrimaryHover: colorGreenT6,
  colorPrimaryDark: colorGreenT7,
  colorSecondary: colorNeutralT1,
  colorSecondaryForeground: colorNeutralT8,
  colorSecondaryHover: colorNeutralT2,
  colorTertiary: colorBlueT5,
  colorTertiaryForeground: colorWhite,
  colorTertiaryHover: colorBlueT6,
  colorTypographyPrimary: colorNeutralT9,
  colorTypographySecondary: colorNeutralT8,
  colorTypographyTertiary: colorNeutralT7,
  colorTypographyError: colorRedT5,
  colorStateSuccess: colorGreenT2,
  colorStateInfo: colorBlueT2,
  colorStateError: colorRedT1,
  colorWhite,
};
