import { useMemo } from "react";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import { Store } from "../../ui/store/store";
import type { User } from "../index";
import type { AuthService, AuthState } from "./auth";

const PINS_KEY = "ZOOPINZ_UNLOCKED_PINS";
const ANON_USER_ID_KEY = "ZOOPINZ_ANON_USER_ID";

function serializePins(pins: string[]): string {
  return btoa(JSON.stringify(pins));
}

function deserializePins(serializedPins: string): string[] {
  return JSON.parse(atob(serializedPins));
}

export class AnonymousAuthClient implements AuthService {
  readonly store: Store<AuthState>;
  constructor(
    private readonly localStorage: Storage,
    private readonly generateUniqueId = generateUniqueID,
  ) {
    this.store = new Store<AuthState>(this.getAuthState());
  }

  addPinToUser(_: User, pinId: string) {
    return this.addPin(pinId);
  }

  async hasPin(_: User, pinId: string) {
    const pins = this.getPins();
    return pins.some(id => id === pinId);
  }

  private readonly addPin = async (pinId: string) => {
    const newPins = [...this.getPins(), pinId];
    const newUniquePins = Array.from(new Set(newPins));
    this.localStorage.setItem(PINS_KEY, serializePins(newUniquePins));
  };

  private readonly getPins = () => {
    const maybePins = this.localStorage.getItem(PINS_KEY);
    return maybePins ? deserializePins(maybePins) : [];
  };

  private readonly getId = () => {
    let id = this.localStorage.getItem(ANON_USER_ID_KEY);
    if (!id) {
      id = this.generateUniqueId();
      this.localStorage.setItem(ANON_USER_ID_KEY, id);
    }
    return id;
  };

  readonly getAuthState = () => {
    return {
      user: {
        isAnonymous: true as const,
        id: this.getId(),
        attributes: {
          pin_ids: this.getPins(),
        },
      },
      authStatus: "authenticated" as const,
    };
  };

  useAuthenticator() {
    const id = this.getId();
    const maybePins = this.localStorage.getItem(PINS_KEY);
    return useMemo(() => ({
      user: {
        isAnonymous: true as const,
        id: id,
        attributes: {
          pin_ids: maybePins ? deserializePins(maybePins) : [],
        },
      },
      authStatus: "authenticated" as const,
    }), [id, maybePins]);
  }

  logout() {
    console.error("Invalid Auth API invocation on an Anonymous user");
    return Promise.resolve();
  }

  deleteAccount() {
    console.error("Invalid Auth API invocation on an Anonymous user");
    return Promise.resolve();
  }

  changePassword() {
    console.error("Invalid Auth API invocation on an Anonymous user");
    return Promise.resolve("SUCCESS" as const);
  }

  updateProfile() {
    console.error("Invalid Auth API invocation on an Anonymous user");
    return Promise.resolve("SUCCESS" as const);
  }
}
