import * as React from "react";
import type { Image as ImageData } from "services";
import { createCarousel as createCarouselImpl } from "ui/carousel/create";
import { Carousel } from "./carousel";

type CarouselFactoryOpts = {
  images: ImageData[],
};

export function createCarousel({ images }: CarouselFactoryOpts) {
  const { Component: CarouselImpl } = createCarouselImpl();
  return React.memo(() => <Carousel BaseCarousel={CarouselImpl} images={images}/>);
}
