import type { Client } from "@prismicio/client";
import type {
  EmptyImageFieldImage,
  EmptyLinkField,
  FilledContentRelationshipField,
  FilledImageFieldImage,
  FilledLinkToWebField,
} from "@prismicio/types";
import { LinkType } from "@prismicio/types";
import { exists, Preconditions, UnreachableError } from "ui/preconditions";
import { Store } from "ui/store/store";
import type { Brand, HomePage, Pin } from "../index";
import { HomePageSliceType } from "../index";
import type { ContentService } from "./content";
import type { AllDocumentTypes, AnimalDocument, BrandDocument } from "./content-typegen";
import { UNKNOWN_BRAND } from "./fake";

function isDocument<T, U>(
  linkField: EmptyLinkField<"Document"> | FilledContentRelationshipField<T, string, U>,
): linkField is FilledContentRelationshipField<T, string, U> {
  if (linkField.link_type !== "Document") {
    return false;
  }
  const filledLinkField = linkField as FilledContentRelationshipField<T, string, U>;
  return filledLinkField.id != null && filledLinkField.isBroken == false;
}

function isWebLink(
  linkField: EmptyLinkField<"Web"> | FilledLinkToWebField,
): linkField is FilledLinkToWebField {
  if (linkField.link_type !== "Web") {
    return false;
  }
  return (linkField as FilledLinkToWebField).url != null;
}

function isImage(
  imageField: EmptyImageFieldImage | FilledImageFieldImage,
): imageField is FilledImageFieldImage {
  return imageField.url != null;
}

export class PrismicContentClient implements ContentService {
  readonly store = new Store({
    pins: [],
    brands: [],
  } as { pins: Pin[], brands: Brand[] });

  constructor(
    private readonly prismicContentClientImpl: Client<AllDocumentTypes>,
    private readonly decorateUrl = (url: string) => url,
  ) {
  }

  private async deserializePin(response: AnimalDocument): Promise<Pin> {
    const brand: Brand = isDocument<"brand", BrandDocument["data"]>(response.data.brand)
      ? await this.getBrand(
        Preconditions.checkExists(response.data.brand.uid, "Brand UID is required"),
      )
      : UNKNOWN_BRAND;
    return {
      ...response.data,
      id: response.uid,
      brand,
    } as Pin;
  }

  async getPin(id: string): Promise<Pin> {
    const document = await this.prismicContentClientImpl.getByUID("animal", id);
    return this.deserializePin(document);
  }

  async getPins(): Promise<Pin[]> {
    const documents = await this.prismicContentClientImpl.getAllByType("animal");
    return Promise.all(documents.map(this.deserializePin.bind(this)));
  }

  private deserializeBrand(response: BrandDocument): Brand {
    return {
      id: response.uid,
      name: response.data.name ?? "Unknown",
      logo: {
        image: isImage(response.data.logo_image) ? response.data.logo_image : {
          url: "", // TODO: Add in a Placeholder URL
          alt: "Image could not be loaded.",
        },
        href: this.decorateUrl(
          isWebLink(response.data.logo_link) ? response.data.logo_link.url : "#",
        ),
      },
      colors: {
        primary: Preconditions.checkExists(
          response.data.color_primary,
          "Color Primary is required",
        ),
        primaryBackground: Preconditions.checkExists(
          response.data.color_primary_background,
          "Color Primary Background is required",
        ),
        primaryForeground: Preconditions.checkExists(
          response.data.color_primary_foreground,
          "Color Primary Foreground is required",
        ),
        secondaryBackground: response.data.color_secondary_background ?? undefined,
        secondaryForeground: response.data.color_secondary_foreground ?? undefined,
      },
      featuredPinIds: response.data.featured_pins.filter(item => isDocument(item.pin)).map(item =>
        Preconditions.checkExists((item.pin as FilledContentRelationshipField).uid)
      ),
    };
  }

  async getBrand(brandId: string): Promise<Brand> {
    const response = await this.prismicContentClientImpl.getByUID("brand", brandId);
    return this.deserializeBrand(response);
  }

  async getBrands(): Promise<Brand[]> {
    const response = await this.prismicContentClientImpl.getAllByType("brand");
    return Promise.all(response.map(this.deserializeBrand.bind(this)));
  }

  async getHomePage(): Promise<HomePage> {
    const [document, pins] = await Promise.all([
      this.prismicContentClientImpl.getSingle("home_page"),
      this.getPins(),
    ]);
    const pinsById = pins.reduce((acc, cv) => {
      acc.set(cv.id, cv);
      return acc;
    }, new Map<string, Pin>());
    return document.data.body.map(slice => {
      const { slice_type: sliceType } = slice;
      switch (sliceType) {
        case "hero":
          return {
            type: HomePageSliceType.HERO,
            title: slice.primary.title ?? "",
            subtitle: slice.primary.subtitle ?? "",
            image: {
              url: slice.primary.image.url ?? "",
              alt: slice.primary.image.alt ?? "",
            },
            buttons: slice.items.map(item => ({
              label: item.button_label ?? "",
              url: {
                url: this.decorateUrl((item.button_link as FilledLinkToWebField).url),
              },
            })),
          };
        case "cards_carousel":
          if (slice.primary.button_link.link_type !== LinkType.Web) {
            throw new Error();
          }
          return {
            type: HomePageSliceType.CARDS_LIST,
            title: slice.primary.title ?? "",
            description: slice.primary.description,
            button: {
              label: slice.primary.button_label ?? "",
              url: {
                url: this.decorateUrl((slice.primary.button_link as FilledLinkToWebField).url),
              },
            },
            pins: slice.items.filter(item => item.pin.link_type === LinkType.Document).map(item => {
              const pin = item.pin as FilledContentRelationshipField<
                "animal",
                string,
                AnimalDocument["data"]
              >;
              const pinData = pinsById.get(pin.uid ?? "");
              if (!pinData) {
                return undefined;
              }
              return {
                id: pin.uid ?? "",
                name: pinData.name ?? "",
                icon: {
                  url: pinData.icon.url ?? "",
                  alt: pinData.icon.alt ?? "",
                },
              };
            }).filter(exists),
          };
        case "customer_logos":
          return {
            type: HomePageSliceType.CUSTOMER_LOGOS,
            title: slice.primary.title ?? "",
            description: slice.primary.description,
            logos: slice.items.map(item => ({
              link: item.link.link_type === LinkType.Web
                ? (item.link as FilledLinkToWebField).url
                : "",
              image: {
                url: item.logo.url ?? "",
                alt: item.logo.alt ?? "",
              },
            })),
          };
        case "faqs":
          return {
            type: HomePageSliceType.FAQ,
            title: slice.primary.title ?? "",
            items: slice.items.map(item => ({
              question: item.question ?? "",
              answer: item.answer,
            })),
          };
        case "banner":
          return {
            type: HomePageSliceType.BANNER,
            title: slice.primary.title ?? "",
            description: slice.primary.description ?? "",
            image: {
              url: slice.primary.image.url ?? "",
              alt: slice.primary.image.alt ?? "",
            },
            button: {
              label: slice.primary.button_label ?? "",
              url: {
                url: this.decorateUrl((slice.primary.button_link as FilledLinkToWebField).url),
              },
            },
          };
        default:
          throw new UnreachableError(slice);
      }
    });
  }
}
