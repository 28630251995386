import { Flex } from "@aws-amplify/ui-react";
import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import type { Brand } from "services";
import { PinsBar } from "ui/drawer-ui";
import { PageLayout } from "ui/PageLayout";

export type PinsPinProps = {
  brand: Brand,
  Icon?: React.ComponentType,
  Title?: React.ComponentType,
  Species?: React.ComponentType,
  Carousel?: React.ComponentType,
  Overview?: React.ComponentType,
  Traits?: React.ComponentType,
  Challenges?: React.ComponentType,
  Origin?: React.ComponentType,
  Embed?: React.ComponentType,
  HowToHelp?: React.ComponentType,
  CallToAction?: React.ComponentType,
  ActionCards?: React.ComponentType,
};

export const PinsPin: React.FC<PinsPinProps> = ({
  brand,
  Icon,
  Title,
  Species,
  Carousel,
  Overview,
  Traits,
  Challenges,
  Origin,
  Embed,
  HowToHelp,
  CallToAction,
  ActionCards,
}) => {
  return (
    <>
      <PageLayout>
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            gap="1rem"
            style={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            {Icon && <Icon/>}
          </Flex>
          {Title && <Title/>}
          {Species && <Species/>}
          {Carousel && <Carousel/>}
          {Overview && <Overview/>}
          {Traits && <Traits/>}
          {Challenges && <Challenges/>}
          {Origin && <Origin/>}
          {Embed && <Embed/>}
          {HowToHelp && <HowToHelp/>}
          {CallToAction && <CallToAction/>}
          {ActionCards && <ActionCards/>}
        </Flex>
      </PageLayout>
      <ReactRouterLink to="/pinz">
        <PinsBar color={brand.colors.primary}>My collection</PinsBar>
      </ReactRouterLink>
    </>
  );
};
