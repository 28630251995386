import * as React from "react";
import type { PinMetadata } from "services";
import { Text } from "ui/base/typography/typography";
import { IconList } from "ui/icon-list";
import { Container } from "ui/PageLayout";
import { PinIcon } from "ui/pin-icon";
import styles from "./catalog.module.css";

type PinsCatalogProps = {
  pins: PinMetadata[],
  showTitle?: boolean,
};

export const PinsCatalog = React.memo(({ pins, showTitle }: PinsCatalogProps) => (
  <Container style={{ marginTop: 0, paddingTop: 0 }}>
    {showTitle && <Text className={styles.title} headingLevel={2}>Pinz Catalog</Text>}
    <IconList minColumnWidth={150} gap={10}>
      {pins.slice() // Avoid sorting in place
        .sort((a, b) => {
          if (a.disabled === b.disabled) {
            return 0;
          }
          // Disabled items get pushed to the back
          return a.disabled ? 1 : -1;
        }).map(item => (
          <PinIcon
            label={item.name}
            isDisabled={item.disabled}
            key={item.id}
            pinId={item.id}
            imageUrl={item.icon.url}
            width="250px"
            height="250px"
          />
        ))}
    </IconList>
  </Container>
));
