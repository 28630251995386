import { Image } from "@aws-amplify/ui-react";
import classNames from "clsx";
import * as React from "react";
import type { Brand, PinMetadata } from "services";
import { Text } from "ui/base/typography/typography";
import { Carousel } from "ui/carousel/carousel";
import placeholder from "./empty.svg";
import styles from "./pins-carousel.module.css";

export type PinsCarouselPin = PinMetadata & { locked: boolean, featuredBy: Set<string> };
type PinsCarouselProps = {
  title: string,
  rightIcon?: JSX.Element,
  pins: PinsCarouselPin[],
  selectedPin: number,
  onSelectedPinChange: (index: number) => void,
  callToAction?: JSX.Element,
};

export const PinsCarousel = React.memo(({
  title,
  rightIcon,
  pins,
  selectedPin,
  onSelectedPinChange,
  callToAction,
}: PinsCarouselProps) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.titleWrapper}>
        <Text headingLevel={3} className={styles.title}>{title}</Text>
        <div className={styles.rightIcon}>{rightIcon}</div>
      </div>
      <Carousel
        callToAction={callToAction}
        className={styles.carousel}
        wrapperClassName={styles.carousel}
        currentIndex={selectedPin}
        onChange={onSelectedPinChange}
      >
        {pins.length > 0
          ? pins.map(pin => (
            <div
              key={pin.id}
              className={classNames(styles.imageWrapper, {
                [styles.locked]: pin.locked,
              })}
            >
              <Image className={styles.image} alt={pin.icon.alt ?? ""} src={pin.icon.url}/>
            </div>
          ))
          : [
            (
              <React.Fragment key={0}>
                <Image className={styles.placeholderImage} alt="No pins found" src={placeholder}/>
                <Text className={styles.placeholderText} headingLevel={5}>No pins found</Text>
              </React.Fragment>
            ),
          ]}
      </Carousel>
    </div>
  );
});
