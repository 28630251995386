export const colorGreenT1 = "#cceada";
export const colorGreenT2 = "#99d5b5";
export const colorGreenT3 = "#66c08f";
export const colorGreenT4 = "#33ab6a";
export const colorGreenT5 = "#009645";
export const colorGreenT6 = "#007837";
export const colorGreenT7 = "#005a29";
export const colorGreenT8 = "#003c1c";
export const colorGreenT9 = "#001e0e";
export const colorWhite = "#ffffff";
export const colorNeutralT1 = "#d8d8d8";
export const colorNeutralT2 = "#b1b1b1";
export const colorNeutralT3 = "#8b8b8b";
export const colorNeutralT4 = "#646464";
export const colorNeutralT5 = "#3d3d3d";
export const colorNeutralT6 = "#313131";
export const colorNeutralT7 = "#252525";
export const colorNeutralT8 = "#181818";
export const colorNeutralT9 = "#0c0c0c";
export const colorBlueT1 = "#ccd8db";
export const colorBlueT2 = "#99b2b8";
export const colorBlueT3 = "#668b94";
export const colorBlueT4 = "#336571";
export const colorBlueT5 = "#003e4d";
export const colorBlueT6 = "#00323e";
export const colorBlueT7 = "#00252e";
export const colorBlueT8 = "#00191f";
export const colorBlueT9 = "#000c0f";
export const colorRedT1 = "#fbd9da";
export const colorRedT2 = "#f7b3b6";
export const colorRedT3 = "#f38e91";
export const colorRedT4 = "#ef686d";
export const colorRedT5 = "#eb4248";
export const colorRedT6 = "#bc353a";
export const colorRedT7 = "#8d282b";
export const colorRedT8 = "#5e1a1d";
export const colorRedT9 = "#2f0d0e";
