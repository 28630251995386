import { Image } from "@aws-amplify/ui-react";
import * as React from "react";
import type { Brand, Image as ImageData } from "services";
import { Text } from "ui/base/typography/typography";
import styles from "./pin.module.css";

type OriginProps = {
  brand: Brand,
  title: string,
  items: {
    image: ImageData,
    description: string,
  }[],
};

export const Origin = React.memo(({ brand, title, items }: OriginProps) => (
  <div className={styles.section}>
    <Text headingLevel={2} brand={brand}>{title}</Text>
    {items.map((origin, index) => (
      <div key={index}>
        <Image alt={origin.image.alt ?? "Animal origin"} src={origin.image.url}/>
        <Text>{origin.description}</Text>
      </div>
    ))}
  </div>
));
