import classNames from "clsx";
import * as React from "react";
import type { Brand } from "services";
import { Button } from "ui/button";
import { Icon } from "ui/icons/icon";
import { Sheet } from "ui/sheet/sheet";
import { createMapObserver, createObserver, Store } from "ui/store/store";
import { Filter } from "./filter/filter";
import type { PinsCarouselPin } from "./pins-carousel";
import { PinsCarousel } from "./pins-carousel";
import styles from "./pins-carousel.module.css";

type PinsCarouselFactoryOpts = {
  title: string,
  navigate: (to: string) => void,
  parentBrand: Brand,
  // All brands
  brands: Brand[],
  pins: PinsCarouselPin[],
};

export function createPinsCarousel(
  { title, navigate, brands, parentBrand, pins }: PinsCarouselFactoryOpts,
) {
  const store = new Store({ selectedItem: 0 });
  const onSelectedItemChange = (selectedItem: number) => {
    store.update(curr => ({ ...curr, selectedItem }));
  };

  const featuredBrandIds = new Set(pins.flatMap(pin => Array.from(pin.featuredBy)));
  const featuredBrands = new Map(
    brands.filter(brand => featuredBrandIds.has(brand.id)).map(brand => [brand.id, brand]),
  );

  const filters = new Store({
    selectedBrands: featuredBrands,
    isOpen: false,
  });

  const onBrandSelect = (brand: Brand) => {
    const selectedBrands = new Map(filters.get().selectedBrands.entries());
    if (selectedBrands.has(brand.id)) {
      selectedBrands.delete(brand.id);
    } else {
      selectedBrands.set(brand.id, brand);
    }
    filters.update(curr => ({ ...curr, selectedBrands }));

    // Reset the carousel index back to 0.
    onSelectedItemChange(0);
  };
  const toggleModal = (isOpen: boolean) => {
    filters.update(curr => ({ ...curr, isOpen }));
  };

  /**
   * How do we determine what brands should be selectable in the filters?
   * We can't use the brands that have featured pins as the dataset. There could be pins that aren't featured in
   * any brands that the user has collected, and you wouldn't be able to sort by them.
   *
   * What is the relationship exactly between pins and brands?
   *
   * A pin can belong to many brands.
   * A brand can have many pins.
   * Many-to-many.
   */

  const Filters = createObserver(filters)(({ store }) => {
    return (
      <>
        <Icon glyph="filter" color="black" onClick={() => toggleModal(true)}/>
        <Sheet isOpen={store.isOpen} onClose={() => toggleModal(false)}>
          <Filter
            brand={parentBrand}
            brands={Array.from(featuredBrands.values())}
            onBrandSelect={onBrandSelect}
            onClose={() => toggleModal(false)}
            selectedBrands={new Set(store.selectedBrands.keys())}
          />
        </Sheet>
      </>
    );
  });

  return createMapObserver({
    carousel: store,
    filters,
  })(({ store }) => {
    const filteredPins = pins.filter(pin =>
      Array.from(store.filters.selectedBrands.keys()).some(b => pin.featuredBy.has(b))
    );
    const selectedPin = filteredPins[store.carousel.selectedItem];
    const ctaBody = !selectedPin || selectedPin.locked
      ? (
        <>
          {selectedPin && <Icon glyph="lock" color="545b6b" className={styles.lock}/>}
          {selectedPin && selectedPin.name}
        </>
      )
      : (
        <>
          View {selectedPin.name}
          <Icon
            className={styles.arrow}
            glyph="chevron"
            color={parentBrand.colors.primaryForeground}
          />
        </>
      );
    const cta = selectedPin
      ? (
        <Button
          isDisabled={selectedPin.locked}
          variant="secondary"
          brand={parentBrand}
          className={classNames(styles.cta, {
            [styles.disabled]: selectedPin.locked,
          })}
          onClick={() => navigate(`/pinz/${selectedPin.id}`)}
        >
          {ctaBody}
        </Button>
      )
      : undefined;
    return (
      <div className={styles.carouselWrapper}>
        <PinsCarousel
          title={title}
          rightIcon={<Filters/>}
          pins={filteredPins}
          selectedPin={store.carousel.selectedItem}
          onSelectedPinChange={onSelectedItemChange}
          callToAction={cta}
        />
      </div>
    );
  });
}
