import * as React from "react";
import { useState } from "react";
import { Navigation } from "ui/navigation/navigation";
import { useMedia } from "use-media";
import type { ScanPinBootstrap } from "./bootstrap";
import { ScanPin } from "./scan";

type ScanPinFactoryOpts = {
  navigate: (to: string) => void,
  bootstrap: ScanPinBootstrap,
};

export function createScanPin({ navigate, bootstrap }: ScanPinFactoryOpts) {
  return (() => {
    const isDesktop = useMedia({ minWidth: "1000px" });
    const isTablet = useMedia({ minWidth: "768px" });
    const [shouldScanOnWeb, setShouldScanOnWeb] = useState(false);
    React.useEffect(() => {
      if ((window as any).__webview__ == true) {
        window.location.href = "uniwebview://startCamera";
      } else {
        setShouldScanOnWeb(true);
      }
      // if (
      //   (window as any).webkit != null
      //   && (window as any).webkit.messageHandlers != null
      //   && (window as any).webkit.messageHandlers.cameraHandler != null
      //   && (window as any).webkit.messageHandlers.cameraHandler.postMessage != null
      // ) {
      //   (window as any).webkit.messageHandlers.cameraHandler.postMessage("startCamera");
      // } else {
      //   setShouldScanOnWeb(true);
      // }
    });
    return (
      <>
        <Navigation brand={bootstrap.brand}/>
        {shouldScanOnWeb && (
          <ScanPin
            navigate={navigate}
            goBack={() => navigate("/pinz")}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        )}
      </>
    );
  });
}
