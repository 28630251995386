import classNames from "clsx";
import * as React from "react";
import { ReactComponent as ArrowGlyph } from "./glyphs/arrow.svg";
import { ReactComponent as CheckGlyph } from "./glyphs/check.svg";
import { ReactComponent as ChevronGlyph } from "./glyphs/chevron.svg";
import { ReactComponent as CrossGlyph } from "./glyphs/cross.svg";
import { ReactComponent as FilterGlyph } from "./glyphs/filter.svg";
import { ReactComponent as LockGlyph } from "./glyphs/lock.svg";
import styles from "./icon.module.css";

const glyphs = {
  arrow: ArrowGlyph,
  check: CheckGlyph,
  chevron: ChevronGlyph,
  cross: CrossGlyph,
  filter: FilterGlyph,
  lock: LockGlyph,
};

// TODO: Add a rotation prop
type IconProps = {
  glyph: keyof typeof glyphs,
  color: string,
  className?: string,
  direction?: "left" | "right",
  onClick?: () => void,
};

export const Icon = React.memo(
  ({ glyph, color, className, direction = "right", onClick }: IconProps) => {
    const Component = glyphs[glyph];
    return (
      <Component
        onClick={onClick}
        className={classNames(styles.icon, {
          [styles.left]: direction === "left",
          [styles.clickable]: !!onClick,
        }, className)}
        style={{ color }}
      />
    );
  },
);
