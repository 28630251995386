import { Flex } from "@aws-amplify/ui-react";
import * as React from "react";
import type { Brand } from "services";
import { Button } from "ui/button";
import { PageLayout } from "ui/PageLayout";

type PinsHomeProps = {
  ScanButton?: React.ComponentType,
  Body?: React.ComponentType,
  CatalogButton?: React.ComponentType,
};

export const PinsHome = React.memo(({ ScanButton, Body, CatalogButton }: PinsHomeProps) => {
  return (
    <PageLayout>
      {ScanButton && (
        <Flex direction="column" alignItems="center" marginBottom={32}>
          <ScanButton/>
        </Flex>
      )}
      {Body && <Body/>}
      {CatalogButton && (
        <Flex direction="column" alignItems="center" marginBottom={32}>
          <CatalogButton/>
        </Flex>
      )}
    </PageLayout>
  );
});
