import * as React from "react";
import { Text } from "ui/base/typography/typography";
import styles from "./pin.module.css";

type ChallengesProps = {
  title: string,

  challenges: {
    challenge: string,
  }[],
};

export const Challenges = React.memo(({ title, challenges }: ChallengesProps) =>
  challenges.length > 0
    ? (
      <div className={styles.section}>
        <Text headingLevel={2}>{title}</Text>
        <ul style={{ paddingLeft: 24 }}>
          {challenges.map((challenge, index) => (
            <li key={index}>
              <Text marginBlock={0}>{challenge.challenge}</Text>
            </li>
          ))}
        </ul>
      </div>
    )
    : null
);
