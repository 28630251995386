import * as React from "react";
import { QrReader } from "react-qr-reader";
import styled from "styled-components";
import { BackButton } from "ui/BackButton";

type ScanPinProps = {
  goBack: () => void,
  navigate: (to: string) => void,
  isDesktop: boolean,
  isTablet: boolean,
};

export const ScanPin = React.memo(({ goBack, navigate, isDesktop, isTablet }: ScanPinProps) => {
  return (
    <Wrapper>
      <BackButton label="Go back" onClick={goBack}/>
      <QrReader
        onResult={(result: any) => {
          if (result) {
            const url = new URL(result.text);
            navigate(url.pathname);
          }
        }}
        scanDelay={100}
        containerStyle={{
          width: "100%",
          height: isDesktop ? "400px" : isTablet ? "600px" : "100%",
        }}
        videoContainerStyle={{ height: "100%", paddingTop: "0" }}
        videoStyle={{ height: "100%" }}
        constraints={{
          facingMode: "environment",
          width: { ideal: 1080 },
        }}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-top: env(safe-area-inset-top);
  padding-top: 62px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
